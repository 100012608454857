import { createActions } from 'redux-actions';

export const actions = createActions({
  AUTH: {
    START_REGISTRATION: (data) => data,
    FINISH_REGISTRATION: (data) => data,
    FAILED_REGISTRATION: (data) => data,
    START_AUTHENTICATION: (exchange) => exchange,
    FINISH_AUTHENTICATION: (pathname) => pathname,
    HANDLE_URL_CALLBACK: (url) => url,
    SET_ACCESS_TOKEN: (token) => token,
    SET_REFRESH_TOKEN: (token) => token,
    REMOVE_ACCESS_TOKEN: () => {},
    LOAD_CURRENT_USER: () => {},
    IMPERSONATE_USER: (ssid) => ssid,
    RESET_IMPERSONATION: () => {},
    LOGOUT: (state) => state,
  },
}).auth;
