import React from 'react';
import styled from 'styled-components';

import { BaseSection } from '../common/BaseSection';

import { SPACING_32, SPACING_48, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { H1Small, H2, Paragraph, ParagraphSmall } from '../texts';
import { Button } from '../buttons';

import bookMeetingIllustration from '../../assets/images/lead/book-meeting.png';

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
  display: flex;
  gap: 56px;
  background-color: ${accent.sand150};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const BookMeeting = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledBaseSection columns={8} {...rest}>
      <img
        style={{ width: 214, height: 214 }}
        alt="Book meeting illustration"
        src={bookMeetingIllustration}
      />
      <ContentWrapper>
        <TextWrapper>
          <Paragraph>Boka första mötet</Paragraph>
          <H2>Låt allt kretsa kring dig</H2>
          <ParagraphSmall>
            För att kunna ge ett helhetsperspektiv och en holistisk rådgivning
            vill vi lära känna dig. Allt börjar vid det första mötet där vi
            tillsammans kartlägger din nuvarande situation för att lägga grunden
            för din personliga strategi och förmögenhetsplan.
          </ParagraphSmall>
        </TextWrapper>
        <Button style={{ marginRight: 'auto' }}>Boka ett första möte</Button>
      </ContentWrapper>
    </StyledBaseSection>
  );
};
