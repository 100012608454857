import CryptoJS from 'crypto-js';

import { config } from '../../config';

import { AuthError } from '../../api';

export const getToken = async (data) => {
  const tokenUrl = `${config.api.host}/auth/token`;
  const username = config.api.client.user;
  const password = config.api.client.pass;

  // Need to it this way to have a cross platform way to do base64
  const wordArray = CryptoJS.enc.Utf8.parse(`${username}:${password}`);
  const base64AuthHeader = CryptoJS.enc.Base64.stringify(wordArray);

  const result = await fetch(tokenUrl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${base64AuthHeader}`,
    },
  }).then((response) => response.json());

  if (result?.error) {
    throw new AuthError(result?.error);
  }

  return {
    accessToken: result.access_token,
    refreshToken: result.refresh_token,
  };
};

export const getDeepLink = (path = '') => {
  const prefix = `${config.app.urlScheme}://`;
  return prefix + path;
};

export const generateRandomAuthStateString = (length = 32) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export function generateCodeVerifier() {
  const codeVerifier = generateRandomAuthStateString(50);
  return codeVerifier;
}

export function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/[=]/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function generateCodeChallenge(codeVerifier) {
  const codeChallenge = base64URL(CryptoJS.SHA256(codeVerifier));
  return codeChallenge;
}
