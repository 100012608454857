import React from 'react';
import styled, { css } from 'styled-components';

import { bodyFontStack } from '../../fonts';
import {
  accent,
  buttoncolor,
  buttoncoloractive,
  buttoncolordisabled,
  buttoncolorhover,
  textLightest,
} from '../../constants/colors';
import {
  buttonLineHeightLarge,
  buttonLineHeightSmall,
  buttonSizeLarge,
  buttonSizeSmall,
} from '../../constants/text';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { Link } from 'react-router-dom';

const FilledButton = css`
  color: ${(props) => props.textcolor || textLightest};
  background-color: ${(props) => props.buttoncolor || buttoncolor};

  &:hover:not(:disabled) {
    background-color: ${(props) => props.buttoncolorhover || buttoncolorhover};
  }

  &:active:not(:disabled) {
    background-color: ${(props) =>
      props.buttoncoloractive || buttoncoloractive};
  }

  &:disabled,
  &.disabled {
    background-color: ${(props) =>
      props.buttoncolordisabled || buttoncolordisabled};
    color: ${accent.ocean450};
  }
`;

const OutlineButton = css`
  color: ${(props) => props.textcolor || accent.ocean490};
  background-color: transparent;
  border: 1px solid ${(props) => props.buttoncolor || buttoncolor};

  &:hover:not(:disabled) {
    background-color: ${(props) => props.buttoncolorhover || '#EFF2F7'};
  }

  &:active:not(:disabled) {
    background-color: ${(props) => props.buttoncoloractive || '#E7EBF2'};
  }

  &:disabled,
  &.disabled {
    border-color: ${(props) => props.buttoncolordisabled || '#E9E9ED'};
    color: ${accent.ocean440};
  }
`;

const TonalButton = css`
  color: ${(props) => props.textcolor || accent.ocean490};
  background-color: ${(props) => props.buttoncolor || accent.ocean230};

  &:hover:not(:disabled) {
    background-color: ${(props) => props.buttoncolorhover || accent.ocean250};
  }

  &:active:not(:disabled) {
    background-color: ${(props) => props.buttoncoloractive || accent.ocean250};
  }

  &:disabled,
  &.disabled {
    background-color: ${(props) => props.buttonColordisabled || '#E9E9ED'};
    color: ${accent.ocean450};
  }
`;

const TextButton = css`
  color: ${(props) => props.textcolor || accent.ocean490};
  background-color: transparent;

  &:hover:not(:disabled) {
    background-color: ${(props) => props.buttoncolorhover || '#EFF2F7'};
  }

  &:active:not(:disabled) {
    background-color: ${(props) => props.buttoncoloractive || '#E7EBF2'};
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color: ${accent.ocean440};
  }
`;

const ButtonLargStyles = css`
  padding: 14px ${SPACING_24};
  font-size: ${buttonSizeLarge};
  line-height: ${buttonLineHeightLarge};
`;

const ButtonSmallStyles = css`
  padding: ${SPACING_8} ${SPACING_16};
  gap: 6px;
  font-size: ${buttonSizeSmall};
  line-height: ${buttonLineHeightSmall};
`;

const ButtonBaseStyle = css`
  -webkit-appearance: none;
  display: inline-flex;
  gap: ${SPACING_8};
  align-items: center;
  justify-content: center;

  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  font-family: ${bodyFontStack};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  box-shadow: 0 0 0 0 transparent;
  transition: all ${shortAnimationTime} ${easeInOut};

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
`;

const StyledLargeButton = styled.button`
  ${ButtonBaseStyle}

  ${(props) => props.buttonstyle === 'filled' && FilledButton}
  ${(props) => props.buttonstyle === 'outline' && OutlineButton}
  ${(props) => props.buttonstyle === 'tonal' && TonalButton}
  ${(props) => props.buttonstyle === 'text' && TextButton}

  ${ButtonLargStyles}
`;

const StyledSmallButton = styled.button`
  ${ButtonBaseStyle}

  ${(props) => props.buttonstyle === 'filled' && FilledButton}
  ${(props) => props.buttonstyle === 'outline' && OutlineButton}
  ${(props) => props.buttonstyle === 'tonal' && TonalButton}
  ${(props) => props.buttonstyle === 'text' && TextButton}

  ${ButtonSmallStyles}
`;

export const Button = (props) => {
  const {
    to,
    external,
    icon,
    children,
    label,
    buttonstyle = 'filled',
    disabled,
    ...rest
  } = props;

  let as = to && Link;
  if (external && to) {
    as = 'a';
  }

  return (
    <StyledLargeButton
      as={as}
      href={external ? to : null}
      target={to && external ? '_blank' : null}
      to={to}
      buttonstyle={buttonstyle}
      disabled={disabled}
      className={disabled ? 'disabled' : ''}
      {...rest}
    >
      {icon && icon}
      {children || label}
    </StyledLargeButton>
  );
};

export const ButtonSmall = (props) => {
  const {
    to,
    external,
    icon,
    children,
    label,
    buttonstyle = 'filled',
    disabled,
    ...rest
  } = props;

  let as = to && Link;
  if (external && to) {
    as = 'a';
  }

  return (
    <StyledSmallButton
      as={as}
      href={external ? to : null}
      target={to && external ? '_blank' : null}
      to={to}
      buttonstyle={buttonstyle}
      disabled={disabled}
      className={disabled ? 'disabled' : ''}
      {...rest}
    >
      {icon && icon}
      {children || label}
    </StyledSmallButton>
  );
};

export const ButtonRound = (props) => {
  const { to, children, style, buttonstyle = 'filled', ...rest } = props;

  return (
    <ButtonSmall
      buttonstyle={buttonstyle}
      to={to}
      style={{ ...style, borderRadius: 100 }}
      {...rest}
    >
      {children}
    </ButtonSmall>
  );
};
