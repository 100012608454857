import { AsyncStorage } from './AsyncStorage';

export const getAccessToken = async () => AsyncStorage.getItem('accessToken');

export const storeAccessToken = async (accessToken) => {
  Promise.resolve(await AsyncStorage.setItem('accessToken', accessToken));
};

export const deleteAccessToken = () => {
  AsyncStorage.removeItem('accessToken');
};

export const getRefreshToken = async () => AsyncStorage.getItem('refreshToken');

export const storeRefreshToken = async (refreshToken) => {
  Promise.resolve(await AsyncStorage.setItem('refreshToken', refreshToken));
};
