import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  config,
  actions,
  generateCodeVerifier,
  generateCodeChallenge,
} from '@formue-app/core';

import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { actions as authActions } from '../store/auth';

const { qlik: qlikActions } = actions.ui;

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const AuthSsoPage = () => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  const hashParams = new URLSearchParams(document.location.hash.substring(1));

  const [impersonationId] = useState(queryParams.get('impersonationId'));
  const [idToken] = useState(hashParams.get('id_token'));
  const [returnedState] = useState(hashParams.get('state'));
  const [error] = useState(hashParams.get('error'));
  const [errorDescription] = useState(hashParams.get('error_description'));

  const { tenant, clientId, redirectUri } = config.auth.sso.azureAd;

  // Store impersonationId in sessionStorage so that after we are authenticated
  // we will automatically be impersonating this user without the need for the
  // advisor to select the client from the list
  if (impersonationId) {
    window.sessionStorage.setItem('impersonationId', impersonationId);
  }

  useEffect(() => {
    // Make sure we remove qlik session cookie when logging in to prevent cookie from persisting
    // after advisor has changed client in SF.
    dispatch(qlikActions.destroySession());

    // If idToken hash param is present we use that to exchange for a proper access
    // token with the API.
    if (idToken) {
      const storedState = window.sessionStorage.getItem('sso_state');
      // Verify that the state variable matches what we sent to the authorization endpoint.
      // Since we sent a hashed version ("code challenge") to the authorization server we
      // need to hash our locally stored raw value before we compare it.
      if (returnedState !== generateCodeChallenge(storedState)) {
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        );
      }

      // Should we valiate the nonce, does it have any security benefits of doing that in a SPA
      // where an attacker easily can change the source code? Should we send it to the server
      // so the server can validate it?
      // const storedNonce = window.sessionStorage.getItem('sso_nonce');

      // If we have an impersonationId stored in session storage we want to _not_
      // redirect the advisor the the default /clients url after authentication is
      // done, so we pass in a pathname to startAuthentication so that we control
      // the destination when authentication is completed.
      const storedImpersonationId =
        window.sessionStorage.getItem('impersonationId');
      let pathname;
      if (storedImpersonationId) {
        pathname = '/advisor';
      }

      // Store auth method into session storage so that we can redirect a user back to
      // this endpoint if the token expires to authomatically log them in again
      window.sessionStorage.setItem('authenticationMethod', 'employeeSso');

      dispatch(
        authActions.startAuthentication({
          exchange: 'idToken',
          credentials: { idToken },
          pathname,
        })
      );
    } else if (error) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            error: errorDescription,
          }}
        />
      );
    } else {
      const rawStateString = generateCodeVerifier();
      const hashedState = generateCodeChallenge(rawStateString);
      window.sessionStorage.setItem('sso_state', rawStateString);

      const rawNonceString = generateCodeVerifier();
      const hashedNonce = generateCodeChallenge(rawNonceString);
      window.sessionStorage.setItem('sso_nonce', rawNonceString);

      const authorizeUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize
        ?response_type=id_token
        &client_id=${clientId}
        &redirect_uri=${redirectUri}
        &scope=openid+email+profile
        &state=${hashedState}
        &nonce=${hashedNonce}`;

      // Redirect the user to the authorize url
      document.location = authorizeUrl;
    }
  });

  return (
    <LoadingContainer>
      <CenteredActivityIndicator />
    </LoadingContainer>
  );
};
