import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  backgroundWhite,
  accent,
  blackTranslucent,
  uiNegative,
} from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { paragraphLineHeight, paragraphSize } from '../../constants/text';

import { Label } from './Label';
import { ParagraphXSmall } from '../texts';

export const FilledInputStyle = css`
  &:hover:not(:disabled):not(:focus) {
    border-color: ${accent.ocean420};
  }

  &:focus {
    border-color: ${accent.ocean440};
  }

  &:disabled {
    background-color: ${blackTranslucent(0.04)};
    border-color: transparent;
  }
`;

export const OutlineInputStyle = css`
  border-color: ${accent.ocean420};
  background-color: transparent;

  &:hover:not(:disabled):not(:focus) {
    border-color: ${accent.ocean420};
  }

  &:focus {
    border-color: ${accent.ocean440};
  }

  &:disabled {
    background-color: ${blackTranslucent(0.04)};
  }
`;

export const ErrorStyle = css`
  border-color: ${uiNegative};

  &:hover:not(:disabled):not(:focus) {
    border-color: ${uiNegative};
  }

  &:focus {
    border-color: ${uiNegative};
  }
`;

export const StyledInputBase = css`
  appearance: none;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${backgroundWhite};
  font-size: ${paragraphSize};
  line-height: ${paragraphLineHeight};
  color: ${accent.ocean490};
  border: none;
  padding: 11px ${SPACING_16};
  border: 1px solid ${backgroundWhite};
  outline: none;
  min-width: 157px;
  @media ${mobileCondition} {
    min-width: 63px;
  }

  &::placeholder {
    color: ${accent.ocean470};
  }
`;

const StyledInputWrapper = styled.input`
  ${StyledInputBase}

  ${(props) => props.inputstyle === 'filled' && FilledInputStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineInputStyle}
  ${(props) => props.error && ErrorStyle}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const ErrorMessage = styled(ParagraphXSmall)`
  color: ${uiNegative};
`;

export const Input = React.forwardRef((props, ref) => {
  const {
    inputstyle = 'filled',
    label,
    placeholder,
    style,
    value,
    onChange,
    name,
    popoutLabel,
    error,
    errorMessage,
    ...rest
  } = props;

  const [editedOnce, setEditedOnce] = useState(false);

  return (
    <InputWrapper>
      {popoutLabel && label && <Label>{label}</Label>}
      <StyledInputWrapper
        {...rest}
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        inputstyle={inputstyle}
        error={editedOnce && !error && !!value}
        onBlur={() => {
          if (!value) {
            setEditedOnce(false);
          } else {
            setEditedOnce(true);
          }
        }}
      />
      {editedOnce && !error && !!value && errorMessage ? (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      ) : null}
    </InputWrapper>
  );
});
