import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { contentMaxWitdh } from '../../../constants/grid';
import { accent } from '../../../constants/colors';
import { H2 } from '../../texts';
import { useQueryState } from 'react-router-use-location-state';
import { StrategyProductsTable } from '../../investmentStrategies/StrategyProductsTable';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  background-color: ${accent.sand1};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${contentMaxWitdh}px;
`;

export const PortfolioCharacteristicsDetails = () => {
  const [queryParamsSelectedStrategy] = useQueryState('selectedStrategies', '');

  return (
    <Wrapper>
      <Container>
        <H2 style={{ paddingBottom: 10 }}>
          {t('wealthPlan:investmentStrategy:portfolioManagedByFormue')}
        </H2>
        <StrategyProductsTable
          strategyId={queryParamsSelectedStrategy}
          displayProducts
        />
      </Container>
    </Wrapper>
  );
};
