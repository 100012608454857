import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  percentage,
  t,
  selectors,
  getSocialResponsibilityIndicators,
  getSustainabilityTrend,
} from '@formue-app/core';
import { notDesktopCondition } from '../../../../constants/media';
import { BaseSection } from '../../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../../../../constants/spacing';
import { H1, H2, Paragraph, ParagraphLarge } from '../../../texts';
import { CollapsibleListItem } from '../../../common/CollapsibleListItem';
import { accent } from '../../../../constants/colors';
import { SocialResponsibilityAccordionContent } from './SocialResponsibilityAccordionContent';
import { TrendBadge } from './TrendBadge';
import { Coverage } from './Coverage';
import { INVERTED_SOCIAL_RESPONSIBILITY_TRENDS } from '@formue-app/core/src/constants/sustainability';

const {
  entities: {
    portfolioLenses: {
      allPortfolioLensesSelector,
      portfolioLensesMetadataSelector,
      socialResponsibilityDataSelector,
    },
  },
} = selectors;

const i18nPrefix = 'sustainability:socialResponsibility';

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING_24} 0 ${SPACING_16} 0;
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const Background = styled.div`
  background-color: ${accent.neutral150};
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  flex-direction: column;
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.neutral150};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
  padding-bottom: ${SPACING_12};
`;

export const SocialResponsibilityModalContent = (props) => {
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);
  const socialResponsibilityData = useSelector(
    socialResponsibilityDataSelector
  );

  // API has been unconsistent with construction of this object
  // therefore we need to make sure the keys we need actually exist
  const objectKeys = portfolioLensesMetadata?.benchmark?.socialResponsibility;
  const socialResponsibilityKeys = objectKeys ? Object.keys(objectKeys) : [];

  const indicators = getSocialResponsibilityIndicators(
    portfolioLenses,
    portfolioLensesMetadata
  );

  return (
    <StyledWrapper>
      <H1>{indicators}/5</H1>
      <ParagraphLarge style={{ paddingBottom: 16 }}>
        {t('sustainability:socialResponsibility:indicatorsImproved')}
      </ParagraphLarge>
      <Wrapper>
        {socialResponsibilityKeys
          .filter((key) => key !== 'globalCompactEvidence')
          .map((key) => {
            const trendSelector = `socialResponsibility.${key}`;
            const value = socialResponsibilityData[key];
            const benchmark =
              portfolioLensesMetadata.benchmark.socialResponsibility[key];

            const trend = getSustainabilityTrend(
              trendSelector,
              'THISYEAR',
              false
            );

            return (
              <Background>
                <StyledCollapsibleListItem
                  onExpand={() => {
                    // This is a hack because the Chart that can be found in Collapsible
                    // expects to have it's own dimensions calculated. However, the Collapsible
                    // actually has "display: none" property so anything inside doesn't have
                    // dimensions set. Luckily, the Chart respondes to 'resize'
                    // event (we made it to be responsive)
                    setTimeout(() => {
                      window.dispatchEvent(new Event('resize'));
                    }, 100);
                  }}
                  title="test"
                  details={
                    <SocialResponsibilityAccordionContent
                      itemKey={key}
                      benchmark={benchmark}
                      socialResponsibilityData={socialResponsibilityData}
                      i18nPrefix={`${i18nPrefix}:${key}`}
                      trendSelector={trendSelector}
                    />
                  }
                  startExpanded={false}
                  padding={0}
                  style={{ boxShadow: 'none' }}
                  detailsBackgroundColor={accent.sand150}
                >
                  <InnerContainer>
                    <TitleWrapper>
                      <H2>{percentage(value * 100, 2, false, false)}</H2>
                      <Paragraph
                        style={{
                          whiteSpace: 'pre-line',
                          maxWidth: 700,
                          marginTop: 0,
                          marginRight: 'auto',
                        }}
                      >
                        {t(`${i18nPrefix}:${key}:text`)}
                      </Paragraph>
                    </TitleWrapper>
                    <TrendBadge
                      title={`${percentage(
                        trend * 100,
                        2,
                        false,
                        false
                      )} ${t('filters:period:THISYEAR').toLowerCase()}`}
                      direction={trend > 0 ? 'Up' : 'Down'}
                      positiveTrend={
                        INVERTED_SOCIAL_RESPONSIBILITY_TRENDS.includes(key)
                          ? trend <= 0
                          : trend > 0
                      }
                    />
                  </InnerContainer>
                </StyledCollapsibleListItem>
              </Background>
            );
          })}
      </Wrapper>
    </StyledWrapper>
  );
};
