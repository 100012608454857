import React from 'react';
import {
  getAssetClassName,
  getColorForAssetClassGroup,
  riskAndLockinValueToText,
  t,
} from '@formue-app/core';

import styled, { css } from 'styled-components';
import { ParagraphSmall, ParagraphXSmall } from '../../texts';
import {
  accent,
  assetClassDefaultColorScheme,
  backgroundWhite,
  hoverBoxShadowLvl3,
} from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';
import { ReactComponent as PyramidIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-small-icon.svg';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

const Bar = styled.div`
  height: 100%;
  opacity: 0.32;
  width: 0;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: calc(100% / 3);
  flex-direction: column;
  position: relative;
  padding: ${SPACING_16};
  box-sizing: border-box;
  height: 25%;
  background-color: rgb(245, 243, 240);
  position: relative;

  ${(props) =>
    props.selected &&
    css`
      border-radius: ${BORDER_RADIUS_LARGE};
      z-index: 1;

      h4,
      h5,
      h6,
      p {
        color: ${accent.velvet490};
      }
      ${Bar} {
        opacity: 1;
      }
    `}
`;

const Item = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(ParagraphSmall)`
  font-weight: 500;
  padding: 0 ${SPACING_8};
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
`;

const Value = styled(ParagraphSmall)`
  font-weight: 500;
  line-height: 100%;
`;

const SelecetedContainer = styled.div`
  position: absolute;
  left: -${SPACING_8};
  right: -${SPACING_8};
  top: -${SPACING_8};
  bottom: -${SPACING_8};
  background-color: ${backgroundWhite};
  border-radius: ${SPACING_32};
  box-shadow: ${hoverBoxShadowLvl3};
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay}s;
  opacity: 0;
  scale: 0.9;

  ${(props) =>
    props.animate &&
    css`
      opacity: 1;
      scale: 1;
    `}
`;

const SelectedLabel = styled(ParagraphXSmall)`
  color: ${backgroundWhite} !important;
  background-color: ${accent.velvet1};
  padding: 4px ${SPACING_8};
  border-radius: 16px;
  position: absolute;
  top: -12px;
  left: 24px;
  font-weight: 500;
`;

const ItemsContainer = styled.div`
  opacity: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) =>
    props.flipped &&
    css`
      opacity: 0;
    `}
`;

const LeftLabel = styled(ParagraphXSmall)`
  position: absolute;
  transform: rotate(270deg);
  left: -76px;
  top: 50%;
  margin-top: -5px;
  width: 100px;
  text-align: center;
  color: ${accent.ocean490} !important;
`;

const BottomLabel = styled(ParagraphXSmall)`
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
  text-align: center;
  color: ${accent.ocean490} !important;
`;

export const MatrixItem = (props) => {
  const {
    assetClassGroups = [],
    selected,
    index,
    risk,
    lockIn,
    animate,
    ...rest
  } = props;

  return (
    <Container selected={selected} delay={index * 0.2} {...rest}>
      {selected && (
        <SelecetedContainer animate={animate}>
          <SelectedLabel>
            {t('wealthPlan:investmentProfile:yourProfile')}
          </SelectedLabel>

          <PyramidIcon height={57} width={57} style={{ margin: '0 40px' }} />

          {assetClassGroups.length ? (
            <ItemsContainer>
              {assetClassGroups.map((group, index) => (
                <Item key={`asset-class-item-${index}`}>
                  <Dot
                    style={{
                      backgroundColor: getColorForAssetClassGroup(
                        group.id,
                        assetClassDefaultColorScheme
                      ),
                    }}
                  />
                  <Title>{getAssetClassName(group.id)}</Title>
                  <Value>{`${Math.round(group.min * 100)}-${Math.round(
                    group.max * 100
                  )}%`}</Value>
                </Item>
              ))}
            </ItemsContainer>
          ) : null}
        </SelecetedContainer>
      )}
      {risk === 0 && <LeftLabel>{riskAndLockinValueToText(lockIn)}</LeftLabel>}
      {lockIn == null && (
        <BottomLabel>{riskAndLockinValueToText(risk)}</BottomLabel>
      )}
    </Container>
  );
};
