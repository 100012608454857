import React from 'react';
import styled, { css } from 'styled-components';

import {
  BORDER_RADIUS_LARGE,
  SPACING_12,
  SPACING_16,
} from '../../constants/spacing';
import { backgroundWhite } from '../../constants/colors';
import { ParagraphXSmall } from '../texts';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

const Box = styled.div`
  width: ${(props) => `${props.width}px` || 'auto'};
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%);
  display: inline-flex;
  padding: ${SPACING_12} ${SPACING_16};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: ${BORDER_RADIUS_LARGE};
  border: 1px rgba(56, 87, 149, 0.1);
  background: ${backgroundWhite};
  box-shadow: 2px 6px 7px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all ${shortAnimationTime} ${easeInOut};

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      top: -14px;
    `}

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${backgroundWhite};
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Tooltip = (props) => {
  return (
    <Box {...props}>
      <ParagraphXSmall>{props.children}</ParagraphXSmall>
    </Box>
  );
};
