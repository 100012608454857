import camelcase from 'camelcase';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { periodToDate } from './dates';
import { portfolioLensesTimelineSelector } from '../../store/entities/selectors/portfolioLenses';
import { filterSustainabilityTimelineChartData } from '../..';
import { INVERTED_SOCIAL_RESPONSIBILITY_TRENDS } from '../../constants/sustainability';

export const getEmissionComparisonIconType = (type) => {
  switch (camelcase(type)) {
    case 'carsDrivenByYear':
      return 'Emissions';
    case 'smartphonesCharged':
      return 'Phone Charges';
    default:
      return 'Energy';
  }
};

const getLastYearValues = (data) => {
  const current = data[data.length - 1];
  const currentDate = periodToDate(current.period);
  const lastYearsDecember = data.filter((item) => {
    const date = periodToDate(item.period);

    return (
      date.getFullYear() === currentDate.getFullYear() - 1 &&
      date.getMonth() === 11
    );
  })[0];

  // There are cases when the "last year" won't be available 
  // For example a "new user" that has no data for previous year.
  // In that case we want to return data[0]
  if (lastYearsDecember) return lastYearsDecember;
  return data[0];
};

export const getSocialResponsibilityIndicators = (data, meta) => {
  if (!meta.benchmark) return null;

  const { socialResponsibility } = meta.benchmark;

  const current = data[data.length - 1];
  const lastYear = getLastYearValues(data);

  let betterIndicatorsThisYear = 0;
  Object.keys(socialResponsibility).forEach((indicatorKey) => {
    const currentValue = current.socialResponsibility[indicatorKey];
    const lastYearValue = lastYear.socialResponsibility[indicatorKey];
    if (INVERTED_SOCIAL_RESPONSIBILITY_TRENDS.includes(indicatorKey)) {
      if (currentValue < lastYearValue) betterIndicatorsThisYear++;
    } else {
      if (currentValue > lastYearValue) betterIndicatorsThisYear++;
    }
  });
  return betterIndicatorsThisYear;
};

export const getThisYearTemperatureRise = (data) => {
  const current = data[data.length - 1];
  const lastYear = getLastYearValues(data);

  const temperatureDifference =
    current.temperature.impliedRise - lastYear.temperature.impliedRise;

  return (temperatureDifference * 100) / lastYear.temperature.impliedRise;
};

export const getSustainabilityTrend = (
  selector,
  period = 'TWELVEMONTHS',
  percentage = false
) => {
  const allPortfolioLenses = useSelector(portfolioLensesTimelineSelector);
  const current = allPortfolioLenses[allPortfolioLenses.length - 1];
  const filteredByPeriodData = filterSustainabilityTimelineChartData(
    allPortfolioLenses,
    period
  );

  const previousDataPoint = filteredByPeriodData[0];

  const currentValue = get(current, selector);
  const previousValue = get(previousDataPoint, selector);

  if (!currentValue) return null;

  const trendDifference = currentValue - previousValue;

  if (!percentage) {
    return trendDifference;
  }

  return (trendDifference * 100) / previousValue;
};
