import { takeLeading, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  actions as coreActions,
  getAccessToken,
  getRefreshToken,
  checkIfTokenCanBeUsed,
} from '@formue-app/core';

import { actions } from './actions';
import { actions as authActions } from '../auth';

const {
  entities: { operationalMessages },
  ui: { portfolio: portfolioActions },
} = coreActions;

function* initialize() {
  yield put(operationalMessages.indexRequestBegin());

  yield takeLeading(actions.init, function* () {
    const accessToken = yield call(getAccessToken);
    const refreshToken = yield call(getRefreshToken);

    // If we have a refresh token and not an access token, or an access token that's about to expire,
    // we want to exchange the refresh token for a new access token.
    if (refreshToken) {
      const tokenCanBeUsed = checkIfTokenCanBeUsed(accessToken);
      if (!tokenCanBeUsed) {
        return yield put(
          authActions.startAuthentication({
            exchange: 'refreshToken',
            credentials: {
              token: refreshToken,
            },
          })
        );
      }
    }

    if (accessToken) {
      yield put(authActions.setAccessToken(accessToken));
      const isEmployee = yield select((state) => state.auth.isEmployee);

      // If we have an impersonation ID in session storage that means we are in impersonation
      // mode and rather than bootstrap the app in normal mode we kick of the impersonate action
      // as we would do if the advisors selected the user manually in the list.
      if (isEmployee) {
        yield put(authActions.loadCurrentUser());
        const impersonationId =
          window.sessionStorage.getItem('impersonationId');

        if (impersonationId) {
          yield put(authActions.impersonateUser(impersonationId));
        } else {
          // If this is an employee, but we have not selected a client yet, make sure they are
          // redirected to the client select screen.
          yield put(push('/advisor/clients'));
        }
      } else {
        yield put(actions.load());
      }
    } else {
      const location = yield select((state) => state.router.location);
      const pathname = location?.pathname;
      if (pathname !== '/login') {
        yield put(push('/login'));
      }
    }
  });
}

function* load() {
  yield takeLeading(actions.load, function* ({ payload: ssid }) {
    yield put(authActions.loadCurrentUser());

    yield put(portfolioActions.init(ssid));
  });
}

export const sagas = [initialize, load];
