import React from 'react';
import { BasePage } from './BasePage';

export const FullscreenPage = (props) => {
  const { children, style } = props;
  return (
    <BasePage style={style} {...props}>
      {children}
    </BasePage>
  );
};
