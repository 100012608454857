import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';

import { config, t } from '@formue-app/core';

import { actions as authActions } from '../../store/auth';

import { accent, blackTranslucent, textLightest } from '../../constants/colors';
import {
  SPACING_12,
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_40,
  SPACING_48,
} from '../../constants/spacing';

import { H2, Paragraph } from '../texts';
import { ButtonSmall } from '../buttons';
import { SpriteIcon } from './SpriteIcon';
import { Modal } from './Modal';
import { easeInOut, longAnimationTime } from '../../constants/animation';

const enter = (props) => keyframes`
  0% { right: -100%; }
  100% { right: 0; }
`;

const Wrapper = styled.div`
  background-color: ${accent.raspberry1};
  position: fixed;
  bottom: ${SPACING_24};
  padding: ${SPACING_16} 0;
  padding-right: ${SPACING_40};
  padding-left: ${SPACING_20};
  display: flex;
  gap: ${SPACING_12};
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 0 0 10px 0 ${blackTranslucent(0.25)};
  cursor: pointer;
  right: 0;
  /* right: -100%;
  animation: ${enter} ${longAnimationTime} ${easeInOut} 0.5s forwards; */
`;

export const DemoIndicator = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Wrapper onClick={() => setModalIsOpen(true)}>
        <SpriteIcon id="Info" size={16} inverted={true} navigational={true} />
        <Paragraph style={{ color: textLightest, fontWeight: 600 }}>
          {t('home:demoIndicator:buttonTitle')}
        </Paragraph>
      </Wrapper>
      <Modal
        contentWidth={576}
        padding={SPACING_48}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <H2 style={{ marginBottom: SPACING_24 }}>
            {t('home:demoIndicator:title')}
          </H2>
          <Paragraph
            style={{ marginBottom: SPACING_32, whiteSpace: 'break-spaces' }}
          >
            {t('home:demoIndicator:body', {
              loginType: t(
                `home:demoIndicator:authMetod:${config.countryCode.toLowerCase()}`
              ),
              platform: t(
                `home:demoIndicator:${config.app.platform.toLowerCase()}`
              ),
            })}
          </Paragraph>
          <ButtonSmall
            buttonstyle="tonal"
            icon={<SpriteIcon id="Logout" navigational={true} size={12} />}
            buttoncolor={accent.raspberry250}
            buttoncoloractive={accent.raspberry250}
            buttoncolorhover={accent.raspberry2}
            style={{ marginLeft: 'auto' }}
            onClick={() =>
              dispatch(
                authActions.logout({
                  url: config.domain,
                })
              )
            }
          >
            {t('home:demoIndicator:logOutButton')}
          </ButtonSmall>
        </div>
      </Modal>
    </>
  );
};
