import React, { useState } from 'react';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_48,
} from '../../../constants/spacing';
import { contentMaxWitdh } from '../../../constants/grid';
import { accent, backgroundWhite } from '../../../constants/colors';

import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { H2 } from '../../texts';
import { MultipleSwitch } from '../../formElements';
import { CostTable } from '../../advisorsCorner/strategySimulator/CostTable';
import { CostDetailsHeader } from '../investmentStrategyComponents/CostDetailsHeader';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  background-color: ${accent.sand1};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${contentMaxWitdh}px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING_16};
`;

const {
  entities: {
    investmentStrategies: { investmentStrategyByIdSelector },
    investmentStrategyCosts: {
      investmentStrategyCostsByStrategyIdSelector,
      investmentStrategyCostMetadataByIdSelector,
    },
  },
} = selectors;

export const CostDetails = (props) => {
  const strategies = useEnabledStrategies();
  const [queryParamsSelectedStrategy] = useQueryState('selectedStrategies', '');

  const [dataExtractor, setDataExtractor] = useState('percentage');

  const strategy = useSelector(
    investmentStrategyByIdSelector(queryParamsSelectedStrategy)
  );

  const investmentStrategyCosts = useSelector(
    investmentStrategyCostsByStrategyIdSelector(queryParamsSelectedStrategy)
  );

  const metadata = useSelector(
    investmentStrategyCostMetadataByIdSelector(queryParamsSelectedStrategy)
  );

  const advisoryFeeRelevant = metadata?.advisoryFeeRelevant;
  const structuringFeeRelevant = metadata?.structuringFeeRelevant;
  const twoColumnTableCondition = advisoryFeeRelevant || structuringFeeRelevant;

  if (!strategies.length) return null;

  return (
    <Wrapper>
      <Container>
        {twoColumnTableCondition ? (
          <CostDetailsHeader costData={investmentStrategyCosts} />
        ) : null}
        <div style={{ marginTop: SPACING_48 }}>
          <TitleWrapper>
            <H2>{t('advisor:strategySimulator:cost:detailsTitle')}</H2>
            <MultipleSwitch
              backgroundColor={backgroundWhite}
              activeBackgroundColor={accent.ocean230}
              color={accent.ocean270}
              activeColor={accent.ocean490}
              onChange={(value) => {
                setDataExtractor(value);
              }}
              options={[
                {
                  label: t('advisor:strategySimulator:cost:valueInPercentage'),
                  value: 'percentage',
                },
                {
                  label: t('advisor:strategySimulator:cost:valueInKr'),
                  value: 'amount',
                },
              ]}
            />
          </TitleWrapper>

          <CostTable
            managementType={strategy?.managementType}
            dataExtractor={dataExtractor}
            costData={investmentStrategyCosts}
            advisoryFeeRelevant={advisoryFeeRelevant}
            structuringFeeRelevant={structuringFeeRelevant}
          />
        </div>
      </Container>
    </Wrapper>
  );
};
