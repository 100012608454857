import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  t,
  selectors,
  useActivePromotedTodos,
  useResource,
  config,
} from '@formue-app/core';

import { dismissedPasskeysOnboardingSelector } from '../../store/ui/passkeys/selectors';

import { SPACING_24 } from '../../constants/spacing';

import { GridElement } from '../layout';
import { H2 } from '../texts';

import { VisibleInCountry } from '../../components/common/VisibleInCountry';
import { TodoListCompact } from '../../components/todo/TodoListCompact';
import { QuizInfoCard } from './infoCards/QuizInfoCard';
import { TaxInfoCard } from './infoCards/TaxInfoCard';
import { PromotedTasks } from './PromotedTasks';
import { PromotedSigningTasks } from './PromotedSigningTasks';

import { PasskeysOnboardingPromotion } from '../passkeys/PasskeysOnboardingPromotion';

const { currentUserSelector } = selectors.ui.user;
const { openSelector: openSigningTasksSelector } =
  selectors.entities.signingEvents;

export const TodoHomeContainer = (props) => {
  const {
    taxSEContainEnabled,
    taxNOContainEnabled,
    quizEnabled = true,
  } = props;
  const user = useSelector(currentUserSelector);
  const [quizEnabledLocal, setQuizEnabledLocal] = useState(false);
  const [quizColumns, setQuizColumns] = useState(8);
  const [todoColumns, setTodoColumns] = useState(12);
  const promotedTodos = useActivePromotedTodos();

  const dismissedPasskeysOnboarding = useSelector(
    dismissedPasskeysOnboardingSelector
  );

  useResource(['SIGNING_EVENTS/INDEX']);
  const openSigningTasks = useSelector(openSigningTasksSelector);

  useEffect(() => {
    if (
      (config.countryCode === 'no' && taxNOContainEnabled) ||
      (config.countryCode === 'se' && taxSEContainEnabled)
    ) {
      setQuizColumns(4);
      setTodoColumns(4);
    } else {
      if (quizEnabledLocal) {
        setTodoColumns(4);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizEnabledLocal]);

  useEffect(() => {
    if (user) {
      if (!user.email || (!user.isLead && user.quizResultUrl)) {
        setQuizEnabledLocal(false);
      } else {
        setQuizEnabledLocal(quizEnabled);
      }
    }
  }, [user, quizEnabled]);

  return (
    <>
      <GridElement columns={12} style={{ marginTop: SPACING_24 }}>
        <H2>{t('dashboard:todo:todo')}</H2>
      </GridElement>

      {!dismissedPasskeysOnboarding ? <PasskeysOnboardingPromotion /> : null}

      <PromotedTasks tasks={promotedTodos} />
      <PromotedSigningTasks tasks={openSigningTasks} />

      <>
        <VisibleInCountry
          country={[
            taxNOContainEnabled ? 'no' : null,
            taxSEContainEnabled ? 'se' : null,
          ]}
        >
          <TaxInfoCard columns={quizEnabledLocal ? 4 : 8} />
        </VisibleInCountry>
        {quizEnabledLocal ? (
          <QuizInfoCard columns={quizColumns} user={user} />
        ) : null}
        <TodoListCompact columns={todoColumns}></TodoListCompact>
      </>
      {/* ) : (
        <>
          {quizEnabledLocal ? <QuizInfoCard user={user} /> : null}
          <TodoListCompact columns={quizEnabledLocal ? 4 : 12} />
        </>
      )} */}
    </>
  );
};
