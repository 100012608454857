import React from 'react';
import styled from 'styled-components';

import { BaseSection } from '../common/BaseSection';

import { SPACING_16, SPACING_24, SPACING_48 } from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { H2 } from '../texts';
import { Button } from '../buttons';

import { SpriteIcon } from '../common/SpriteIcon';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${SPACING_24};
  background-color: ${accent.ocean230};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

export const QuizPromotion = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledBaseSection columns={4} {...rest}>
      <TextWrapper>
        <SpriteIcon id="Risk Tolerance" size={56} />
        <H2>Har du kontroll på allt som bör ingå i en förmögenhetsplan?</H2>
      </TextWrapper>
      <Button buttonstyle="text" style={{ marginRight: 'auto' }}>
        Gör vårt quiz
        <SpriteIconFunctional id="Simple arrow" direction="Right" size={16} />
      </Button>
    </StyledBaseSection>
  );
};
