import React from 'react';
import styled from 'styled-components';

import { BaseSection } from '../common/BaseSection';
import { H2, H2Small, H3, H4, Paragraph, ParagraphXSmall } from '../texts';
import { Button, ButtonSmall } from '../buttons';
import {
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_40,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import { accent, backgroundWhite, textLightest } from '../../constants/colors';
import { CollapsibleListItem } from '../common/CollapsibleListItem';
import { SpriteIcon } from '../common/SpriteIcon';

import costumerReviewIllustration1 from '../../assets/images/lead/costumer-review-1.jpg';
import costumerReviewIllustration2 from '../../assets/images/lead/costumer-review-2.jpg';
import videoPoster from '../../assets/images/lead/video-poster.jpg';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
  background-color: ${accent.sand150};
`;

const Banner = styled(BaseSection)`
  display: flex;
  padding: ${SPACING_40};
  justify-content: space-between;
  background-color: ${accent.sand1};
`;

const TextWrapper = styled.div`
  display: flex;
  max-width: 300px;
  flex-direction: column;
  gap: ${SPACING_12};
  margin-right: auto;
  margin-bottom: ${SPACING_24};
`;

const VideoPoster = styled(BaseSection)`
  max-width: 460px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${videoPoster});
  background-position: center;
  background-size: cover;
`;

const BecomeCustomerSteps = styled.div`
  display: flex;
  gap: ${SPACING_16};
  flex-wrap: wrap;
  padding: 0 ${SPACING_24};
`;

const BecomeCustomerStep = styled(BaseSection)`
  background-color: ${accent.sand150};
  flex-basis: calc(50% - (${SPACING_16} / 2));
  padding: ${SPACING_40};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
  align-items: center;
  text-align: center;
`;

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${accent.ocean4};
`;

const ReviewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  text-align: center;
`;

const CostumerReviewItem = styled(BaseSection)`
  flex-basis: calc(50% - (${SPACING_16} / 2));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 560px;
  padding: ${SPACING_40};

  img {
    width: 250px;
  }
`;

const CustomerReviewWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
  padding: 0 ${SPACING_24};
`;

const PlayButton = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 3px solid ${backgroundWhite};
  border-radius: 50%;
  cursor: pointer;
`;

const BecomeCustomer = () => {
  return (
    <BecomeCustomerSteps>
      <BecomeCustomerStep>
        <SpriteIcon id="Portfolio Report" size={40} />
        <H3>Vi möts vid whiteboarden</H3>
        <Paragraph>
          I första mötet går vi på djupet och ritar upp allt på whiteboarden;
          ditt liv, dina mål och ditt kapital. Detta ligger sedan till grund för
          din personliga förmögenhetsplan.
        </Paragraph>
      </BecomeCustomerStep>
      <BecomeCustomerStep>
        <SpriteIcon id="Strategy" size={40} />
        <H3>Luta dig tillbaka medan en strategi utformas</H3>
        <Paragraph>
          Våra rådgivare skräddarsyr en investeringsstrategi som ledstjärna för
          din förmögenhetsplan och placeringar av kapital baserat på dina mål
          och din riskprofil.
        </Paragraph>
      </BecomeCustomerStep>
      <BecomeCustomerStep>
        <SpriteIcon id="Life Organization" size={40} />
        <H3>Utforska nya investeringsmöjligheter</H3>
        <Paragraph>
          Vår oberoende ställning ger oss tillgång till alla investeringsklasser
          och några av de bästa globala förvaltarna, inklusive
          investeringsmöjligheter i onoterade bolag.
        </Paragraph>
      </BecomeCustomerStep>
      <BecomeCustomerStep>
        <SpriteIcon id="Philanthropy" size={40} />
        <H3>Följ allt i appen eller på webben</H3>
        <Paragraph>
          Din förmögenhetsplan och din investeringsportfölj är alltid
          tillgänglig för dig och täcker mål, prioriteringar, familj, ägande,
          expertråd och investeringsprofil.
        </Paragraph>
      </BecomeCustomerStep>
    </BecomeCustomerSteps>
  );
};

const CustomerReview = () => {
  return (
    <CustomerReviewWrapper>
      <CostumerReviewItem>
        <img
          alt="costumer review illustration"
          src={costumerReviewIllustration1}
        />
        <ReviewTextWrapper>
          <H2Small>
            “Formue har som Private banking-aktör ett helhetsperspektiv som
            ingen annan har.”
          </H2Small>
          <ButtonSmall buttonstyle="tonal" style={{ margin: '0 auto' }}>
            Läs Magnus historia
          </ButtonSmall>
          <ParagraphXSmall>Magnus Devold</ParagraphXSmall>
        </ReviewTextWrapper>
      </CostumerReviewItem>
      <CostumerReviewItem>
        <img
          alt="costumer review illustration"
          src={costumerReviewIllustration2}
        />
        <ReviewTextWrapper>
          <H2Small>
            ”Det har varit ordning och reda och man har lärt känna mig, sett
            vilka behov jag som individ har och vad jag behöver tänka på.”
          </H2Small>
          <ButtonSmall buttonstyle="tonal" style={{ margin: '0 auto' }}>
            Läs Hans historia
          </ButtonSmall>
          <ParagraphXSmall>Hans J Nilsson</ParagraphXSmall>
        </ReviewTextWrapper>
      </CostumerReviewItem>
    </CustomerReviewWrapper>
  );
};

export const FormueInfo = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledBaseSection columns={12} {...rest}>
      <Banner>
        <div>
          <TextWrapper>
            <H2>Varför välja Formue?</H2>
            <Paragraph>
              Formues rådgivare Knut Olsson ger dig en 60 sekunders
              introduktion.
            </Paragraph>
          </TextWrapper>
          <Button buttonstyle="tonal">Se video med Knut Olsson</Button>
        </div>
        <VideoPoster>
          <PlayButton>
            <SpriteIconFunctional id="Play" size={24} color={backgroundWhite} />
          </PlayButton>
        </VideoPoster>
      </Banner>
      <CollapsibleListItem
        details={<BecomeCustomer />}
        detailsStyle={{
          background: accent.sand1,
        }}
        style={{
          marginTop: SPACING_8,
          padding: SPACING_24,
          background: accent.sand1,
          alignItems: 'center',
        }}
      >
        <Number>
          <ParagraphXSmall style={{ fontWeight: 'bold', color: textLightest }}>
            1
          </ParagraphXSmall>
        </Number>
        <H4 style={{ marginRight: 'auto' }}>Så går det till att bli kund</H4>
      </CollapsibleListItem>
      <CollapsibleListItem
        details={<CustomerReview />}
        detailsStyle={{
          background: accent.sand1,
        }}
        style={{
          marginTop: SPACING_8,
          padding: SPACING_24,
          background: accent.sand1,
          alignItems: 'center',
        }}
      >
        <Number>
          <ParagraphXSmall style={{ fontWeight: 'bold', color: textLightest }}>
            2
          </ParagraphXSmall>
        </Number>
        <H4 style={{ marginRight: 'auto' }}>Vad säger våra kunder</H4>
      </CollapsibleListItem>
    </StyledBaseSection>
  );
};
